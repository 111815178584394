// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import "bootstrap";
import "../stylesheets/application";
import "./slick";
import "./custom";
import "./slick.min";
// import ahoy from "ahoy.js";


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

  // send request to store color class

// update user reaction e.g. like/unlike
window.user_reaction =  function( element , checked ) {
  var item_type  = element.data('type')
  var item_id    = element.data('id');
  var item_value = checked
  $.ajax({
    type: "get",
    url: "/users/likes/user_reaction",
    data: {"item_type" : item_type, "item_id" : item_id, "item_value" : item_value},
    contentType: "application/json",
    dataType: "json",
    success: function(res){
      $("label[for='"+item_type+item_id+"']").prev().prop('checked', checked )
      $("label[for='"+item_type+item_id+"']").parent().next().text( res )
    }
  })
}

// change theme of site black / white
window.theme_change = function() {
  if ($('body.white').length > 0){
    $('body').removeClass('white')
    send_request_for_color( '' )
  }
  else{
    $('body').addClass('white')
    send_request_for_color( 'white' )
  }
}
// request to server for change color
window.send_request_for_color = function ( color ){
  $.ajax({
    type: 'GET',
    url: "/set_site_color",
    data: { color: color },
    dataType: "json",
    success: function(data) {
      console.log( data )
    }
  });
}

// open or close menu sidebar
window.open_menu = function (){
 $('.dashboard_sidebaar').toggleClass('active')
 $('.dashboard_right_content').toggleClass('reduce_pad_left')
}

$('.header_search_wrap_btn').click(function(){
  $(this).toggleClass('active')
  $('.search_wrap').toggleClass('active_search')
})


$(document).click(function (event) {
  //if you click on anything except the modal itself or the "open modal" link, close the modal
  if (!$(event.target).closest(".search_wrap, .header_search_wrap_btn").length) {
    $('.header_search_wrap_btn').removeClass('active')
    $('.search_wrap').removeClass('active_search')
  }
});
$(document).click(function(event) {
    //if you click on anything except the modal itself or the "open modal" link, close the modal
    if (!$(event.target).closest(".ab_btn, .navbar-collapse").length) {
    $(".navbar-collapse").removeClass("show");
    $(".navbar-toggler").attr("aria-expanded", "false");
    }
  });