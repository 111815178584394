import consumer from "./consumer"

consumer.subscriptions.create("CommentChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    // Shows current comments
  	$('.'+data.media_class+'-'+data.media_id+'-user-comment').append('<li> <div class="row"> <div class="col-auto chat_user_img"> <img src= "'+data.image+'" class="img-fluid" alt="logo" title="Byoe" > </div> <div class="col chat_user_details pl-0"> <h6 class="chat_username"> <span> '+data.user+' </span> <span class="chat_date pull-right" >'+data.comment_time+' ago</span> </h6> <p> '+data.content+' </p> </div> </div> </li> ')

    // Update comments title
    $('.'+data.media_class+'-'+data.media_id+'_comments').attr("title", data.comment_count+" Comments")

    // Update comments count
    $('.'+data.media_class+'-'+data.media_id+'-comment-count').html(' Comments ( '+ data.comment_count +' )')
    $('.'+data.media_class+'-'+data.media_id+'_comments .icon_text').html(data.comment_count)
  }
});
