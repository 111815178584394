/* #############################################
################ Overlay Page Js ###############
############################################# */

//  save all overlay window in database
window.save_overlay_mobile = function() {
  loading('Saving...')
  dataList = []
  $(".m-screen").map(function() {
    item = {}
    item['media-type']     = $(this).attr('data-media-type')
    item['media-id']       = $(this).attr('data-media-id')
    item['content_type']   = $(this).attr('data-content-type')
    item['href']           = $(this).attr('data-href')
    item['src-img']        = $(this).attr('data-src-img')
    dataList.push(item)
  }).get();

  // send ajax call with overlay current window position
  $.ajax({
    type: 'POST',
    url: "/overlay",
    data: {
      mobile_screen: dataList,
      type: 'mobile_screen'
    },
    dataType: "script",
    success: function(data) {
      // console.log(data)
      $('.preview_btn').removeAttr('data-confirm')
      loading('Saved')
      // $('.save_window').html('Saved');
      setTimeout(function() {
        loading_hide()
      }, 3000);
    }
  });
}
window.save_overlay = function() {
  loading('Saving...')
  dataList = []
  $(".grid-stack-item").map(function() {
    if ($(this).attr('data-gs-height') != undefined) {
      item = {}
      item['media-type'] = $(this).attr('data-media-type')
      item['media-id'] = $(this).attr('data-media-id')
      item['content_type'] = $(this).attr('data-content-type')
      item['href'] = $(this).attr('data-href')
      item['src-img'] = $(this).attr('data-src-img')
      item['data-gs-height'] = $(this).attr('data-gs-height')
      item['data-gs-width'] = $(this).attr('data-gs-width')
      item['data-gs-x'] = $(this).attr('data-gs-x')
      item['data-gs-y'] = $(this).attr('data-gs-y')
      dataList.push(item)
    }
  }).get();

  // send ajax call with overlay current window position
  $.ajax({
    type: 'POST',
    url: "/overlay",
    data: {
      space_window: dataList,
      type: 'space_window'
    },
    dataType: "script",
    success: function(data) {
      // console.log(data)
      $('.preview_btn').removeAttr('data-confirm')
      loading('Saved')
      // $('.save_window').html('Saved');
      setTimeout(function() {
        loading_hide()
      }, 3000);
    }
  });
}

// jw player pause all player of overlay page
$('.play').hide()
window.pause_player = function(){
  $('.grid-stack-item-content .jwplayer').map(function() {
    id = this.id
    if(jwplayer(id).getState() == 'playing')
      jwplayer(id).pause();

  })
  $("iframe").each(function() {
    var src= $(this).attr('src');
    $(this).attr('src',src); });
  pause()
}

// jw player play all player of overlay page
window.play_player = function(){
  $('.grid-stack-item-content .jwplayer').map(function() {
    id = this.id
    console.log(jwplayer(id).getState())
    if(jwplayer(id).getState() == 'paused' || jwplayer(id).getState() == 'idle' || jwplayer(id).getState() == 'complete')
      jwplayer(id).play();
  })
  play()
}

// jw player volume off all player of overlay page
$('.mute').hide()
window.unmute_player = function(){
  $('.grid-stack-item-content .jwplayer').map(function() {
    id = this.id
    if(jwplayer(id).getState() == 'idle' ){
      jwplayer(id).play()
      play()
    }
    jwplayer(id).setMute(true);
  })
  unmute()
}

// jw player volume on all player of overlay page
window.mute_player = function(){
  $('.grid-stack-item-content .jwplayer').map(function() {
    id = this.id
    if(jwplayer(id).getState() == 'idle' ){
      jwplayer(id).play()
      play()
    }
    jwplayer(id).setMute(false);
  })
  mute()
}

// hide/show btn of overlay toolbar on jwplayer play, pause, mute, unmute action
window.play = function(){
  $('.play').hide()
  $('.pause').show()
}
window.pause = function(){
  $('.play').show()
  $('.pause').hide()
}
window.mute = function(){
  $('.mute').hide()
  $('.unmute').show()
}
window.unmute = function(){
  $('.mute').show()
  $('.unmute').hide()
}

// Overlay full screen open js ----------------
window.addFullscreen = function(){
  $('.grid-stack').addClass('full-screen')
  $('.exit-full-screen').show();
}
window.addFullscreen_m = function(){
  $('.fit-s').addClass('full-screen')
  $('.exit-full-screen').show();
}

// Overlay full screen exit/close js ----------------
window.removeFullscreen = function(){
  $('.fit-s').removeClass('full-screen')
  $('.grid-stack').removeClass('full-screen')
  $('.exit-full-screen').hide();
}

// Check Jwplayer present or iframe src url ----------------
// if jwplayer present then hide iframe
// if jwplayer is not present then show iframe with 100% hieght
window.iframe_checker = function(){
  $("iframe").each(function() {
    var jw_len = $(this).parent().children('.jwplayer').length
    if(jw_len > 0){
      $(this).hide()
      $(this).css('height','0%')
    }else{
      $(this).show()
      $(this).css('height','100%')
    }
    grid_stack_checker()
  });
}

// update iframe after select any third party service
window.update_grid_content = function(element) {
  href              = element.data('href')
  third_party_logo_path         = element.data('src-img')
  coming_soon_path = element.data('coming-soon')
  srcdoc = "<div style='width: 100%; height: 100vh; overflow: hidden; background: #fff;'><div style='margin: 0 17px;width: 30%; height: 100%;background: red; float: left; text-align: center;background:url("+coming_soon_path+") center center no-repeat;background-size: contain;'></div><div style='margin: 0 17px;width: 30%; height: 100%;background: yellow; float: right; text-align: center;background:url("+third_party_logo_path+") center center no-repeat;background-size: contain;'></div></div><style> body{margin: 0px;}</style>"

  id = $('#key_id').val()
  $('#item_number_'+id+'_player').remove()
  $('#item_number_'+id).attr('data-content-type', 'iframe' )
  if( href != undefined && href.length > 1 ){
    $('#item_number_'+id).attr('data-href', href )
    $('#item_number_'+id).attr('data-src-img', '' )
    $('#item_number_'+id+' iframe').attr('src', href )
    $('#item_number_'+id+' iframe').removeAttr('srcdoc')
  }
  else{
    $('#item_number_'+id).attr('data-src-img', third_party_logo_path )
    $('#item_number_'+id).attr('data-href', '' )
    $('#item_number_'+id+' iframe').removeAttr('srcdoc')
    $('#item_number_'+id+' iframe').attr('srcdoc', srcdoc )
  }
  $('button.close').click();
  iframe_checker()
}

window.add_srcdoc_attr = function(third_party_logo_path, coming_soon_path, id){
  srcdoc = "<div style='width: 100%; height: 100vh; overflow: hidden; background: #fff;'><div style='margin: 0 17px;width: 30%; height: 100%;background: red; float: left; text-align: center;background:url("+coming_soon_path+") center center no-repeat;background-size: contain;'></div><div style='margin: 0 17px;width: 30%; height: 100%;background: yellow; float: right; text-align: center;background:url("+third_party_logo_path+") center center no-repeat;background-size: contain;'></div></div><style> body{margin: 0px;}</style>"
  $("#"+id).attr('srcdoc', srcdoc)
}

//set key in overlay selection model popup for identify window screen
window.set_key = function(element){
  id = element.data('id')
  $('#key_id').val(id)
  $(".media-select").attr('href', function(i, h) {
    return h + (h.indexOf('?') != -1 ? "&item="+id : "?item="+id);
  });
}

// GridStack JS ----------------------------
window.grid_stack_checker = function(){
  $(".grid-stack-item").each(function() {
    // recreateNode(document.getElementById(this.id));
    var x = $(this).attr('data-gs-x')
    // var w = $(this).attr('data-gs-width')
    if( x == '9' ){
       $(this).attr('data-gs-x', 8 )
       // $(this).attr('data-gs-width', w-1 )
    }
  });
}


/* #############################################
################ Image Preview Js ###############
############################################# */
window.image_preview = function(input , id ) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function(e) {
      $('#'+id).attr('src', e.target.result);
    }
    reader.readAsDataURL(input.files[0]);
  }
}

/* #############################################
################ Loading component Js ###############
############################################# */
window.loading_with_time = function(text){
  $('.loading').show()
  $('.loading .loading-inner').html(text);
  setTimeout(function() {
  $('.loading .loading-inner').html('');
  $('.loading').hide()
  }, 3000);
}

window.loading = function(text){
  $('.loading').show()
  $('.loading .loading-inner').html(text);
}

window.loading_hide = function(){
  $('.loading').hide()
}

window.remove_action_icons = function(){
  $('.resize-icon').remove()
  $('.remove-widget').remove()
  $('.ui-resizable-handle, .ui-resizable-se').remove()
}

/* #############################################
################ Loading component Js ###############
############################################# */

window.comment_box_resize = function(footer){
  elementPosTop = footer.position().top - $(window).scrollTop();
  winHeight     = $(window).innerHeight();
  winWidth = $(window).innerWidth()
  if( 766 < winWidth ){
    f_h = 230+footer.innerHeight()
    if( winHeight < elementPosTop ) {
      $('.live_chatboot_list').attr("style", "height: calc(100vh - 260px);");
    } else {
      $('.live_chatboot_list').attr("style", "height: calc(100vh - "+f_h+"px);");
    }
  }else{
    $('.live_chatboot_list').attr("style", "height: 300px;");
  }
}