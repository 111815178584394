import consumer from "./consumer"

consumer.subscriptions.create("LikeChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Update likes title
  	$('.'+data.media_class+'-'+data.media_id).attr('title', data.like_count+" Likes")

    // Update likes count
  	$('.'+data.media_class+'-'+data.media_id+' .icon_text').html(data.like_count)
  }
});
